<template>
  <div class="welcome"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.welcome {
  width: 100%;
  min-height: calc(100vh - 104px);
  background: url("../../assets/wel_bg.png") no-repeat;
  background-size: cover;
}
</style>
